<template>
    <div class="ces-main" style="height: 100%">
        <div class="boxbutton">
            <el-row>
                <el-col :span="24">
                    <div class="grid-content bg-purple">
                        <el-button type="primary" plain size="mini" v-if="quartzCtlSearchFlag" @click="quartzCtlSearch">查询</el-button>
                        <el-button type="primary" plain size="mini" v-if="quartzCtlBatachSomeFlag" @click="quartzCtlBatachSome">批量运行</el-button>
                        <el-button type="primary" plain size="mini" v-if="quartzCtlStopSomeFlag" @click="quartzCtlStopSome">批量停止</el-button>
                        <el-button type="primary" plain size="mini" v-if="quartzCtlStopAllFlag" @click="quartzCtlStopAll">停止全部定时器</el-button>
                        <el-button type="primary" plain size="mini" v-if="quartzCtlBatachAllFlag" @click="quartzCtlBatachAll">运行全部定时器</el-button>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="tableMain">
            <ces-table
                    size='mini'
                    :isSelection='true'
                    :isIndex='true'
                    :isPagination='true'
                    :isHandle='true'
                    v-loading='loading'
                    :tableData='tableData'
                    :tableCols='tableCols'
                    :pagination='pagination'
                    @refresh="handleCurrentChange"
                    @handleClosButtonClick="handleClosButtonClick"
                    ref='cesTable'
            >
            </ces-table>
        </div>
    </div>
</template>

<script>
    //引入组件
    import cesTable from '../../components/common/table.vue'
    import axios from 'axios'
    import arrayQuery from '../../components/arrayQuery.js'
    export default {
        data(){
            return {
                table: false,
                dialog: false,
                loading: false,
                formLabelWidth: '80px',
                timer: null,
                apiId:'null',
                fieldList:[],
                // 表格
                tableData:[],//表格数据
                tableCols:[ //表格列数据
                    {label:'定时器ID',prop:'quartzId',width:'160',align:'center'},
                    {label:'定时器任务名称',prop:'jobName',width:'160',align:'center'},
                    {label:'定时任务描述',prop:'jobDesc',width:'160',align:'center'},
                    {label:'定时任务分组',prop:'groupName',align:'center'},
                    {label:'是否运行',prop:'isRuning',align:'center',width:'120'},
                    {label:'优先级',prop:'priority',align:'center',width:'120'},
                    {label:'类名',prop:'className',align:'center'},
                    {label:'cron表达式',prop:'cronExpression',align:'center',width:'120'},
                    {label:'状态',prop:'statusName',align:'center',width:'120'},
                    {label:'启动方式',prop:'autoStartName',align:'center',width:'120'},
                    {label:'服务组名称',prop:'serverName',align:'center',width:'120'},
                    {label:'触发器状态',prop:'triggerState',align:'center',width:'120'},
                    {label:'开始时间',prop:'startTime',align:'center',width:'160',
                        formatter: (row, column) => {
                            if(row.startTime == null){
                                return ''
                            }else {
                                return this.$moment(row.startTime).format("YYYY-MM-DD HH:mm:ss")
                            }
                        }
                    },
                    {label:'结束时间',prop:'endTime',align:'center',width:'160',
                        formatter: (row, column) => {
                            if(row.endTime == null){
                                return ''
                            }else {
                                return this.$moment(row.endTime).format("YYYY-MM-DD HH:mm:ss")
                            }
                        }
                    },
                    {label:'上一次触发时间',prop:'previousFireTime',align:'center',width:'160',
                        formatter: (row, column) => {
                            if(row.previousFireTime == null){
                                return ''
                            }else {
                                return this.$moment(row.previousFireTime).format("YYYY-MM-DD HH:mm:ss")
                            }
                        }
                    },
                    {label:'下一次触发时间',prop:'nextFireTime',align:'center',width:'160',
                        formatter: (row, column) => {
                            if(row.nextFireTime == null){
                                return ''
                            }else {
                                return this.$moment(row.nextFireTime).format("YYYY-MM-DD HH:mm:ss")
                            }
                        }
                    },
                    {
                        id: "button",
                        type: "Button",
                        label: "操作",
                        fixed: "right",
                        width: "160",
                        prop: "",
                        align: "center",
                        btnList: [
                            {
                                size:'mini',
                                type:'primary',
                                id: "stopSingle",
                                label: "停止",
                            },
                            {
                                size:'mini',
                                id: "runSingle",
                                type:'warning',
                                label: "运行",
                            }
                        ]
                    },

                ],
                pagination:{ //分页数据
                    pageSize:10,
                    pageNum:1,
                    total:0
                },
                merge:[],
                quartzCtlSearchFlag:true,
                quartzCtlBatachSomeFlag:true,
                quartzCtlStopSomeFlag:true,
                quartzCtlStopAllFlag:true,
                quartzCtlBatachAllFlag:true
            }
        },

        components:{ //引入组件后注册组件
            cesTable
        },
        mounted(){
            this.getTableAndForm();
            let parentId = '3_1_2';
            let currentPageButtons = arrayQuery.text(parentId);
            let _this = this;
            _this.currentPageButtons = currentPageButtons;
            //查询按钮权限
            let quartzCtlSearch = currentPageButtons.find(item => {
                return item.menuId == '3_1_2_1';
            });
            if(quartzCtlSearch == undefined){
                _this.quartzCtlSearchFlag = false;
            }else {
                _this.quartzCtlSearchFlag = true;
            }
            //批量运行按钮权限
            let quartzCtlBatachSome = currentPageButtons.find(item => {
                return item.menuId == '3_1_2_2';
            });
            if(quartzCtlBatachSome == undefined){
                _this.quartzCtlBatachSomeFlag = false;
            }else {
                _this.quartzCtlBatachSomeFlag = true;
            }
            //批量停止按钮权限
            let quartzCtlStopSome = currentPageButtons.find(item => {
                return item.menuId == '3_1_2_3';
            });
            if(quartzCtlStopSome == undefined){
                _this.quartzCtlStopSomeFlag = false;
            }else {
                _this.quartzCtlStopSomeFlag = true;
            }
            //停止运行全部按钮权限
            let quartzCtlStopAll = currentPageButtons.find(item => {
                return item.menuId == '3_1_2_5';
            });
            if(quartzCtlStopAll == undefined){
                _this.quartzCtlStopAllFlag = false;
            }else {
                _this.quartzCtlStopAllFlag = true;
            }
            //运行全部按钮权限
            let quartzCtlBatachAll = currentPageButtons.find(item => {
                return item.menuId == '3_1_2_4';
            });
            if(quartzCtlBatachAll == undefined){
                _this.quartzCtlBatachAllFlag = false;
            }else {
                _this.quartzCtlBatachAllFlag = true;
            }
        },
        methods:{
            //此方法是点击分页时触发的查询，
            handleCurrentChange(){
                this.quartzCtlSearch()
            },
            //获取表格和表单数据
            async getTableAndForm(){
                this.quartzCtlSearch()
            },
            // 获取表格勾选数据
            selectionLineChangeHandle(val){
                this.merge = this.$refs.cesTable.multipleSelection
            },
            //订单查询
            quartzCtlSearch(){
                this.loading = true;
                const data = new URLSearchParams();
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'get',
                    url: `${this.$apiPath}/api/job/control/queryJobsByPage`,
                    data:data
                }).then(res=> {
                    this.tableData = res.data.items;
                    this.pagination.total = res.data.total;
                    this.loading = false;
                });
            },
            //批量运行
            quartzCtlBatachSome(){
                this.selectionLineChangeHandle();
                var quartzIds = [];
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '批量运行必须选中一条数据'
                    });
                }else{
                    for(var i = 0;i<this.merge.length;i++){
                        quartzIds.push(this.merge[i].quartzId)
                    }
                    axios({
                        method: 'post',
                        url: `${this.$apiPath}/api/job/control/runJobs`,
                        data:quartzIds
                    }).then(res=> {
                        if(res.data.success == true){
                            this.$message({
                                message: '定时器批量运行成功',
                                type: 'success'
                            });

                            this.quartzCtlSearch();
                        }else {
                            this.$alert(res.data.msg, '提示', {
                                confirmButtonText: '确定',
                            });
                        }
                    });
                }
            },
            //批量停止
            quartzCtlStopSome(){
                this.selectionLineChangeHandle();
                var quartzIds = [];
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '批量停止必须选中一条数据'
                    });
                }else{
                    for(var i = 0;i<this.merge.length;i++){
                        quartzIds.push(this.merge[i].quartzId)
                    }
                    axios({
                        method: 'post',
                        url: `${this.$apiPath}/api/job/control/stopJobs`,
                        data:quartzIds
                    }).then(res=> {
                        if(res.data.success == true){
                            this.$message({
                                message: '定时器批量停止成功',
                                type: 'success'
                            });

                            this.quartzCtlSearch();
                        }else {
                            this.$alert(res.data.msg, '提示', {
                                confirmButtonText: '确定',
                            });
                        }
                    });
                }
            },
            //停止全部定时器
            quartzCtlStopAll(){
                axios.get(`${this.$apiPath}/api/job/control/stopAll/`).then(res=>{
                    if(res.data.success == true){
                        this.$message({
                            message: '停止全部定时器成功',
                            type: 'success'
                        });

                        this.quartzCtlSearch();
                    }else {
                        this.$alert(res.data.msg, '提示', {
                            confirmButtonText: '确定',
                        });
                    }
                })
            },
            //运行全部定时器
            quartzCtlBatachAll(){
                axios.get(`${this.$apiPath}/api/job/control/runAllJobs/`).then(res=>{
                    if(res.data.success == true){
                        this.$message({
                            message: '运行全部定时器成功',
                            type: 'success'
                        });

                        this.quartzCtlSearch();
                    }else {
                        this.$alert(res.data.msg, '提示', {
                            confirmButtonText: '确定',
                        });
                    }
                })
            },
            handleClosButtonClick(val) {
                if(val.row == 'stopSingle'){
                    //停止
                    const jobName = val.index.jobName;
                    axios.get(`${this.$apiPath}/api/job/control/stopJob/`+ jobName).then(res=>{
                        if(res.data.success == true){
                            this.$message({
                                message: '操作成功',
                                type: 'success'
                            });

                            this.quartzCtlSearch();
                        }else {
                            this.$alert(res.data.msg, '提示', {
                                confirmButtonText: '确定',
                            });
                        }
                    })
                }else if(val.row == 'runSingle'){
                    //运行
                    const quartzId = val.index.quartzId;
                    axios.get(`${this.$apiPath}/api/job/control/runJob/`+ quartzId).then(res=>{
                        if(res.data.success == true){
                            this.$message({
                                message: '操作成功',
                                type: 'success'
                            });

                            this.quartzCtlSearch();
                        }else {
                            this.$alert(res.data.msg, '提示', {
                                confirmButtonText: '确定',
                            });
                        }
                    })
                }
            }
        }
    }
</script>
<style>
    .tableMain .ces-table-page{
        height: 100%;
    }
    .tableMain .ces-table{
        height: 100%;
    }
    .tableMain .el-table th{
        padding: 0;
        height: 35px;
    }
    .tableMain .el-table td{
        padding: 0;
        height: 35px;
    }
</style>
<style scoped>
    .boxbutton{
        width: 100%;
        height: 40px;
    }
    .tableMain{
        width: 100%;
        height: 82%;
    }
</style>
